const importers = {
    body: [
        ({ path }: any) => import(`./bodies/${path}Body`),
        [
            'campaign', 'dynaudio', 'dynimage', 'dynvideo', 'game', 'job', 'notification', 'sale', 'ticket',
            'promise', 'presult', 'segment', 'wdefinition', 'dataquery', 'ad',
        ],
    ],
    content: [
        ({path}: any) => import(`./contents/${path}Content`),
        [
            'widget',
        ],
    ],
    widget: [
        ({path}: any) => import(`./widgets/${path}Widget`),
        [
            'tenant_current_day_donations_by_all', 'tenant_current_day_donations_by_day', 'tenant_current_day_donations_by_hour', 'tenant_current_day_donations_by_month',
            'tenant_current_day_donations_by_semester', 'tenant_current_day_donations_by_trimester', 'tenant_current_day_donations_by_week', 'tenant_current_day_donations_by_year',
            'tenant_current_month_donations_by_all', 'tenant_current_month_donations_by_day', 'tenant_current_month_donations_by_hour', 'tenant_current_month_donations_by_month',
            'tenant_current_month_donations_by_semester', 'tenant_current_month_donations_by_trimester', 'tenant_current_month_donations_by_week', 'tenant_current_month_donations_by_year',
            'tenant_current_quarter_donations_by_all', 'tenant_current_quarter_donations_by_day', 'tenant_current_quarter_donations_by_hour', 'tenant_current_quarter_donations_by_month',
            'tenant_current_quarter_donations_by_semester', 'tenant_current_quarter_donations_by_trimester', 'tenant_current_quarter_donations_by_week', 'tenant_current_quarter_donations_by_year',
            'tenant_current_semester_donations_by_all', 'tenant_current_semester_donations_by_day', 'tenant_current_semester_donations_by_hour', 'tenant_current_semester_donations_by_month',
            'tenant_current_semester_donations_by_semester', 'tenant_current_semester_donations_by_trimester', 'tenant_current_semester_donations_by_week', 'tenant_current_semester_donations_by_year',
            'tenant_current_week_donations_by_all', 'tenant_current_week_donations_by_day', 'tenant_current_week_donations_by_hour', 'tenant_current_week_donations_by_month',
            'tenant_current_week_donations_by_semester', 'tenant_current_week_donations_by_trimester', 'tenant_current_week_donations_by_week', 'tenant_current_week_donations_by_year',
            'tenant_current_year_donations_by_all', 'tenant_current_year_donations_by_day', 'tenant_current_year_donations_by_hour', 'tenant_current_year_donations_by_month',
            'tenant_current_year_donations_by_semester', 'tenant_current_year_donations_by_trimester', 'tenant_current_year_donations_by_week', 'tenant_current_year_donations_by_year',
            'tenant_last_day_donations_by_all', 'tenant_last_day_donations_by_day', 'tenant_last_day_donations_by_hour', 'tenant_last_day_donations_by_month',
            'tenant_last_day_donations_by_semester', 'tenant_last_day_donations_by_trimester', 'tenant_last_day_donations_by_week', 'tenant_last_day_donations_by_year',
            'tenant_last_month_donations_by_all', 'tenant_last_month_donations_by_day', 'tenant_last_month_donations_by_hour', 'tenant_last_month_donations_by_month',
            'tenant_last_month_donations_by_semester', 'tenant_last_month_donations_by_trimester', 'tenant_last_month_donations_by_week', 'tenant_last_month_donations_by_year',
            'tenant_last_quarter_donations_by_all', 'tenant_last_quarter_donations_by_day', 'tenant_last_quarter_donations_by_hour', 'tenant_last_quarter_donations_by_month',
            'tenant_last_quarter_donations_by_semester', 'tenant_last_quarter_donations_by_trimester', 'tenant_last_quarter_donations_by_week', 'tenant_last_quarter_donations_by_year',
            'tenant_last_semester_donations_by_all', 'tenant_last_semester_donations_by_day', 'tenant_last_semester_donations_by_hour', 'tenant_last_semester_donations_by_month',
            'tenant_last_semester_donations_by_semester', 'tenant_last_semester_donations_by_trimester', 'tenant_last_semester_donations_by_week', 'tenant_last_semester_donations_by_year',
            'tenant_last_week_donations_by_all', 'tenant_last_week_donations_by_day', 'tenant_last_week_donations_by_hour', 'tenant_last_week_donations_by_month',
            'tenant_last_week_donations_by_semester', 'tenant_last_week_donations_by_trimester', 'tenant_last_week_donations_by_week', 'tenant_last_week_donations_by_year',
            'tenant_last_year_donations_by_all', 'tenant_last_year_donations_by_day', 'tenant_last_year_donations_by_hour', 'tenant_last_year_donations_by_month',
            'tenant_last_year_donations_by_semester', 'tenant_last_year_donations_by_trimester', 'tenant_last_year_donations_by_week', 'tenant_last_year_donations_by_year',
            'tenant_current_day_paymentAmounts_by_all', 'tenant_current_day_paymentAmounts_by_day', 'tenant_current_day_paymentAmounts_by_hour', 'tenant_current_day_paymentAmounts_by_month',
            'tenant_current_day_paymentAmounts_by_semester', 'tenant_current_day_paymentAmounts_by_trimester', 'tenant_current_day_paymentAmounts_by_week', 'tenant_current_day_paymentAmounts_by_year',
            'tenant_current_month_paymentAmounts_by_all', 'tenant_current_month_paymentAmounts_by_day', 'tenant_current_month_paymentAmounts_by_hour', 'tenant_current_month_paymentAmounts_by_month',
            'tenant_current_month_paymentAmounts_by_semester', 'tenant_current_month_paymentAmounts_by_trimester', 'tenant_current_month_paymentAmounts_by_week', 'tenant_current_month_paymentAmounts_by_year',
            'tenant_current_quarter_paymentAmounts_by_all', 'tenant_current_quarter_paymentAmounts_by_day', 'tenant_current_quarter_paymentAmounts_by_hour', 'tenant_current_quarter_paymentAmounts_by_month',
            'tenant_current_quarter_paymentAmounts_by_semester', 'tenant_current_quarter_paymentAmounts_by_trimester', 'tenant_current_quarter_paymentAmounts_by_week', 'tenant_current_quarter_paymentAmounts_by_year',
            'tenant_current_semester_paymentAmounts_by_all', 'tenant_current_semester_paymentAmounts_by_day', 'tenant_current_semester_paymentAmounts_by_hour', 'tenant_current_semester_paymentAmounts_by_month',
            'tenant_current_semester_paymentAmounts_by_semester', 'tenant_current_semester_paymentAmounts_by_trimester', 'tenant_current_semester_paymentAmounts_by_week', 'tenant_current_semester_paymentAmounts_by_year',
            'tenant_current_week_paymentAmounts_by_all', 'tenant_current_week_paymentAmounts_by_day', 'tenant_current_week_paymentAmounts_by_hour', 'tenant_current_week_paymentAmounts_by_month',
            'tenant_current_week_paymentAmounts_by_semester', 'tenant_current_week_paymentAmounts_by_trimester', 'tenant_current_week_paymentAmounts_by_week', 'tenant_current_week_paymentAmounts_by_year',
            'tenant_current_year_paymentAmounts_by_all', 'tenant_current_year_paymentAmounts_by_day', 'tenant_current_year_paymentAmounts_by_hour', 'tenant_current_year_paymentAmounts_by_month',
            'tenant_current_year_paymentAmounts_by_semester', 'tenant_current_year_paymentAmounts_by_trimester', 'tenant_current_year_paymentAmounts_by_week', 'tenant_current_year_paymentAmounts_by_year',
            'tenant_last_day_paymentAmounts_by_all', 'tenant_last_day_paymentAmounts_by_day', 'tenant_last_day_paymentAmounts_by_hour', 'tenant_last_day_paymentAmounts_by_month',
            'tenant_last_day_paymentAmounts_by_semester', 'tenant_last_day_paymentAmounts_by_trimester', 'tenant_last_day_paymentAmounts_by_week', 'tenant_last_day_paymentAmounts_by_year',
            'tenant_last_month_paymentAmounts_by_all', 'tenant_last_month_paymentAmounts_by_day', 'tenant_last_month_paymentAmounts_by_hour', 'tenant_last_month_paymentAmounts_by_month',
            'tenant_last_month_paymentAmounts_by_semester', 'tenant_last_month_paymentAmounts_by_trimester', 'tenant_last_month_paymentAmounts_by_week', 'tenant_last_month_paymentAmounts_by_year',
            'tenant_last_quarter_paymentAmounts_by_all', 'tenant_last_quarter_paymentAmounts_by_day', 'tenant_last_quarter_paymentAmounts_by_hour', 'tenant_last_quarter_paymentAmounts_by_month',
            'tenant_last_quarter_paymentAmounts_by_semester', 'tenant_last_quarter_paymentAmounts_by_trimester', 'tenant_last_quarter_paymentAmounts_by_week', 'tenant_last_quarter_paymentAmounts_by_year',
            'tenant_last_semester_paymentAmounts_by_all', 'tenant_last_semester_paymentAmounts_by_day', 'tenant_last_semester_paymentAmounts_by_hour', 'tenant_last_semester_paymentAmounts_by_month',
            'tenant_last_semester_paymentAmounts_by_semester', 'tenant_last_semester_paymentAmounts_by_trimester', 'tenant_last_semester_paymentAmounts_by_week', 'tenant_last_semester_paymentAmounts_by_year',
            'tenant_last_week_paymentAmounts_by_all', 'tenant_last_week_paymentAmounts_by_day', 'tenant_last_week_paymentAmounts_by_hour', 'tenant_last_week_paymentAmounts_by_month',
            'tenant_last_week_paymentAmounts_by_semester', 'tenant_last_week_paymentAmounts_by_trimester', 'tenant_last_week_paymentAmounts_by_week', 'tenant_last_week_paymentAmounts_by_year',
            'tenant_last_year_paymentAmounts_by_all', 'tenant_last_year_paymentAmounts_by_day', 'tenant_last_year_paymentAmounts_by_hour', 'tenant_last_year_paymentAmounts_by_month',
            'tenant_last_year_paymentAmounts_by_semester', 'tenant_last_year_paymentAmounts_by_trimester', 'tenant_last_year_paymentAmounts_by_week', 'tenant_last_year_paymentAmounts_by_year',
            'tenant_current_day_sales_by_all', 'tenant_current_day_sales_by_hour', 'tenant_current_day_sales_by_day', 'tenant_current_day_sales_by_month',
            'tenant_current_day_sales_by_semester', 'tenant_current_day_sales_by_trimester', 'tenant_current_day_sales_by_week', 'tenant_current_day_sales_by_year',
            'tenant_current_month_sales_by_all', 'tenant_current_month_sales_by_day', 'tenant_current_month_sales_by_hour', 'tenant_current_month_sales_by_month',
            'tenant_current_month_sales_by_semester', 'tenant_current_month_sales_by_trimester', 'tenant_current_month_sales_by_week', 'tenant_current_month_sales_by_year',
            'tenant_current_quarter_sales_by_all', 'tenant_current_quarter_sales_by_day', 'tenant_current_quarter_sales_by_hour', 'tenant_current_quarter_sales_by_month',
            'tenant_current_quarter_sales_by_semester', 'tenant_current_quarter_sales_by_trimester', 'tenant_current_quarter_sales_by_week', 'tenant_current_quarter_sales_by_year',
            'tenant_current_semester_sales_by_all', 'tenant_current_semester_sales_by_day', 'tenant_current_semester_sales_by_hour', 'tenant_current_semester_sales_by_month',
            'tenant_current_semester_sales_by_semester', 'tenant_current_semester_sales_by_trimester', 'tenant_current_semester_sales_by_week', 'tenant_current_semester_sales_by_year',
            'tenant_current_week_sales_by_all', 'tenant_current_week_sales_by_day', 'tenant_current_week_sales_by_hour', 'tenant_current_week_sales_by_month',
            'tenant_current_week_sales_by_semester', 'tenant_current_week_sales_by_trimester', 'tenant_current_week_sales_by_week', 'tenant_current_week_sales_by_year',
            'tenant_current_year_sales_by_all', 'tenant_current_year_sales_by_day', 'tenant_current_year_sales_by_hour', 'tenant_current_year_sales_by_month',
            'tenant_current_year_sales_by_semester', 'tenant_current_year_sales_by_trimester', 'tenant_current_year_sales_by_week', 'tenant_current_year_sales_by_year',
            'tenant_last_day_sales_by_all', 'tenant_last_day_sales_by_day', 'tenant_last_day_sales_by_hour', 'tenant_last_day_sales_by_month',
            'tenant_last_day_sales_by_semester', 'tenant_last_day_sales_by_trimester', 'tenant_last_day_sales_by_week', 'tenant_last_day_sales_by_year',
            'tenant_last_month_sales_by_all', 'tenant_last_month_sales_by_day', 'tenant_last_month_sales_by_hour', 'tenant_last_month_sales_by_month',
            'tenant_last_month_sales_by_semester', 'tenant_last_month_sales_by_trimester', 'tenant_last_month_sales_by_week', 'tenant_last_month_sales_by_year',
            'tenant_last_quarter_sales_by_all', 'tenant_last_quarter_sales_by_day', 'tenant_last_quarter_sales_by_hour', 'tenant_last_quarter_sales_by_month',
            'tenant_last_quarter_sales_by_semester', 'tenant_last_quarter_sales_by_trimester', 'tenant_last_quarter_sales_by_week', 'tenant_last_quarter_sales_by_year',
            'tenant_last_semester_sales_by_all', 'tenant_last_semester_sales_by_day', 'tenant_last_semester_sales_by_hour', 'tenant_last_semester_sales_by_month',
            'tenant_last_semester_sales_by_semester', 'tenant_last_semester_sales_by_trimester', 'tenant_last_semester_sales_by_week', 'tenant_last_semester_sales_by_year',
            'tenant_last_week_sales_by_all', 'tenant_last_week_sales_by_day', 'tenant_last_week_sales_by_hour', 'tenant_last_week_sales_by_month',
            'tenant_last_week_sales_by_semester', 'tenant_last_week_sales_by_trimester', 'tenant_last_week_sales_by_week', 'tenant_last_week_sales_by_year',
            'tenant_last_year_sales_by_all', 'tenant_last_year_sales_by_day', 'tenant_last_year_sales_by_hour', 'tenant_last_year_sales_by_month',
            'tenant_last_year_sales_by_semester', 'tenant_last_year_sales_by_trimester', 'tenant_last_year_sales_by_week', 'tenant_last_year_sales_by_year',
            'tenant_current_day_tickets_by_all', 'tenant_current_day_tickets_by_day', 'tenant_current_day_tickets_by_hour', 'tenant_current_day_tickets_by_month',
            'tenant_current_day_tickets_by_semester', 'tenant_current_day_tickets_by_trimester', 'tenant_current_day_tickets_by_week', 'tenant_current_day_tickets_by_year',
            'tenant_current_month_tickets_by_all', 'tenant_current_month_tickets_by_day', 'tenant_current_month_tickets_by_hour', 'tenant_current_month_tickets_by_month',
            'tenant_current_month_tickets_by_semester', 'tenant_current_month_tickets_by_trimester', 'tenant_current_month_tickets_by_week', 'tenant_current_month_tickets_by_year',
            'tenant_current_quarter_tickets_by_all', 'tenant_current_quarter_tickets_by_day', 'tenant_current_quarter_tickets_by_hour', 'tenant_current_quarter_tickets_by_month',
            'tenant_current_quarter_tickets_by_semester', 'tenant_current_quarter_tickets_by_trimester', 'tenant_current_quarter_tickets_by_week', 'tenant_current_quarter_tickets_by_year',
            'tenant_current_semester_tickets_by_all', 'tenant_current_semester_tickets_by_day', 'tenant_current_semester_tickets_by_hour', 'tenant_current_semester_tickets_by_month',
            'tenant_current_semester_tickets_by_semester', 'tenant_current_semester_tickets_by_trimester', 'tenant_current_semester_tickets_by_week', 'tenant_current_semester_tickets_by_year',
            'tenant_current_week_tickets_by_all', 'tenant_current_week_tickets_by_day', 'tenant_current_week_tickets_by_hour', 'tenant_current_week_tickets_by_month',
            'tenant_current_week_tickets_by_semester', 'tenant_current_week_tickets_by_trimester', 'tenant_current_week_tickets_by_week', 'tenant_current_week_tickets_by_year',
            'tenant_current_year_tickets_by_all', 'tenant_current_year_tickets_by_day', 'tenant_current_year_tickets_by_hour', 'tenant_current_year_tickets_by_month',
            'tenant_current_year_tickets_by_semester', 'tenant_current_year_tickets_by_trimester', 'tenant_current_year_tickets_by_week', 'tenant_current_year_tickets_by_year',
            'tenant_last_day_tickets_by_all', 'tenant_last_day_tickets_by_day', 'tenant_last_day_tickets_by_hour', 'tenant_last_day_tickets_by_month',
            'tenant_last_day_tickets_by_semester', 'tenant_last_day_tickets_by_trimester', 'tenant_last_day_tickets_by_week', 'tenant_last_day_tickets_by_year',
            'tenant_last_month_tickets_by_all', 'tenant_last_month_tickets_by_day', 'tenant_last_month_tickets_by_hour', 'tenant_last_month_tickets_by_month',
            'tenant_last_month_tickets_by_semester', 'tenant_last_month_tickets_by_trimester', 'tenant_last_month_tickets_by_week', 'tenant_last_month_tickets_by_year',
            'tenant_last_quarter_tickets_by_all', 'tenant_last_quarter_tickets_by_day', 'tenant_last_quarter_tickets_by_hour', 'tenant_last_quarter_tickets_by_month',
            'tenant_last_quarter_tickets_by_semester', 'tenant_last_quarter_tickets_by_trimester', 'tenant_last_quarter_tickets_by_week', 'tenant_last_quarter_tickets_by_year',
            'tenant_last_semester_tickets_by_all', 'tenant_last_semester_tickets_by_day', 'tenant_last_semester_tickets_by_hour', 'tenant_last_semester_tickets_by_month',
            'tenant_last_semester_tickets_by_semester', 'tenant_last_semester_tickets_by_trimester', 'tenant_last_semester_tickets_by_week', 'tenant_last_semester_tickets_by_year',
            'tenant_last_week_tickets_by_all', 'tenant_last_week_tickets_by_day', 'tenant_last_week_tickets_by_hour', 'tenant_last_week_tickets_by_month',
            'tenant_last_week_tickets_by_semester', 'tenant_last_week_tickets_by_trimester', 'tenant_last_week_tickets_by_week', 'tenant_last_week_tickets_by_year',
            'tenant_last_year_tickets_by_all', 'tenant_last_year_tickets_by_day', 'tenant_last_year_tickets_by_hour', 'tenant_last_year_tickets_by_month',
            'tenant_last_year_tickets_by_semester', 'tenant_last_year_tickets_by_trimester', 'tenant_last_year_tickets_by_week', 'tenant_last_year_tickets_by_year',
        ],
    ],
    screen: [
        ({path}: any) => import(`./screens/${path}Screen`),
        [
            'home', 'query'
        ],
    ],
    tab: [
        ({ path }: any) => import(`./tabs/${path}Tab`),
        [
            'draw/infos', 'draw/winners',
            'organization/games', 'organization/infos', 'organization/integrations', 'organization/sales',
            'organization/tickets', 'organization/users', 'organization/versions',
            'registration/infos', 'registration/steps',
        ],
    ],
    toolbar: [
        ({ path }: any) => import(`./toolbars/${path}Toolbar`),
        [
            'main_app', 'dynimage', 'dynvideo', 'game', 'gimport', 'greminder', 'job', 'notification', 'order',
            'oreminder', 'organization', 'preminder', 'sale', 'reminder', 'gpreset', 'opreset', 'automation',
            'wdefinition', 'wconfiguration', 'seller', 'sellergroup', 'book', 'grouping', 'theme', 'exportrule',
            'opt', 'ad', 'tenant', 'dfontfamily', 'bookpayment', 'bunch',
        ],
    ],
    extra_toolbar: [
        ({ path }: any) => import(`./extra-toolbars/${path}ExtraToolbar`),
        [
            'game', 'integration',
        ],
    ],
    row_action: [
        ({ path }: any) => import(`./row-actions/${path}RowAction`),
        [
            'go_public_page_qrcode',
        ],
    ],
};

export default importers;

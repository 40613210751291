const item_selections = `
                id
                bunch
                bunchCode
                bunchName
                occurrence
                name
                game
                gameCode
                gameName
                organizationCode
                organizationName
                overline
                description
                tags
                token
                mainImage {
                    available
                    url
                }
                mainImage {
                    url
                    available
                }
                thumbnailImage {
                    url
                    available
                }
                collectModeUrl
                collectModeUrlType
                collectModeUrlText
                collectModeUrlLabel
                collectModeEmail
                collectModePhone
                collectModeMessage
`;
const page_items_selections = `
                id
                bunch
                bunchCode
                bunchName
                occurrence
                name
                gameCode
                gameName
                organizationCode
                organizationName
                overline
                description
                tags
                token
                mainImage {
                    available
                    url
                }
                mainImage {
                    url
                    available
                }
                thumbnailImage {
                    url
                    available
                }
                collectModeUrl
                collectModeUrlType
                collectModeUrlText
                collectModeUrlLabel
                collectModeEmail
                collectModePhone
                collectModeMessage
`;

const page_selections = `cursor count items { ${page_items_selections} }`;

export const CREATE_GIFTCUSTOMIZATION = (gql: any) => gql`
    mutation($data: CreateGiftcustomizationInput!) {
        createGiftcustomization(data: $data) {
            id
        }
    }
`;
export const GET_GIFTCUSTOMIZATION = (gql: any) => gql`
    query($id: ID!) {
        getGiftcustomization(id: $id) {
            ${item_selections}
        }
    }
`;
export const FIND_GIFTCUSTOMIZATIONS = (gql: any) => gql`
    query findGiftcustomizations($offset: String, $limit: Int) {
        findGiftcustomizations(offset: $offset, limit: $limit) {
            ${page_selections}
        }
    }
`;
export const SEARCH_GIFTCUSTOMIZATIONS = (gql: any) => gql`
    query searchGiftcustomizations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGiftcustomizations(offset: $offset, limit: $limit, sort: $sort) {
            ${page_selections}
        }
    }
`;
export const SEARCH_GAME_GIFTCUSTOMIZATIONS = (gql: any) => gql`
    query searchGameGiftcustomizations($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGiftcustomizations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            ${page_selections}
        }
    }
`;
export const FIND_GAME_GIFTCUSTOMIZATIONS = (gql: any) => gql`
    query findGameGiftcustomizations($id: String!, $offset: String, $limit: Int) {
        findGameGiftcustomizations(id: $id, offset: $offset, limit: $limit) {
            ${page_selections}
        }
    }
`;
export const FIND_BUNCHE_GIFTCUSTOMIZATIONS = (gql: any) => gql`
    query findBuncheGiftcustomizations($id: String!, $offset: String, $limit: Int) {
        findBuncheGiftcustomizations:findBunchGiftcustomizations(id: $id, offset: $offset, limit: $limit) {
            ${page_selections}
        }
    }
`;
export const DELETE_GIFTCUSTOMIZATION = (gql: any) => gql`
    mutation deleteGiftcustomization($id: ID!) {
        deleteGiftcustomization(id: $id) {
            id
        }
    }
`;
export const UPDATE_GIFTCUSTOMIZATION = (gql: any) => gql`
    mutation updateGiftcustomization($id: ID!, $data: UpdateGiftcustomizationInput!) {
        updateGiftcustomization(id: $id, data: $data) {
            id
        }
    }
`;

const t = {
    icon: 'fa-award',
    menus: {
        main: { section: 'settings', priority: 22 },
    },
    new: {
        attributes: [
            'name', 'locale', 'country', 'mainImage', 'thumbnailImage',
            'bunch', 'occurrence',
            'overline', 'description', 'longDescription',
            'collectModeEmail', 'collectModePhone', 'collectModeMessage', 'collectModeUrl',
            'collectModeUrlType', 'collectModeUrlText', 'collectModeUrlLabel',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$bunch!', '$occurrence', '$name',
                    '$description', '$longDescription', '$overline'
                ]],
                ['block', [
                    '$collectModeEmail', '$collectModePhone', '$collectModeMessage', '$collectModeUrl',
                    '$collectModeUrlType', '$collectModeUrlText', '$collectModeUrlLabel',
                    '$mainImage', '$thumbnailImage', '$locale', '$country',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'name', 'locale', 'country', 'mainImage', 'thumbnailImage',
            'bunch', 'occurrence',
            'overline', 'description', 'longDescription',
            'collectModeEmail', 'collectModePhone', 'collectModeMessage', 'collectModeUrl',
            'collectModeUrlType', 'collectModeUrlText', 'collectModeUrlLabel',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$bunch!', '$occurrence', '$name',
                    '$description', '$longDescription', '$overline',
                ]],
                ['block', [
                    '$collectModeEmail', '$collectModePhone', '$collectModeMessage', '$collectModeUrl',
                    '$collectModeUrlType', '$collectModeUrlText', '$collectModeUrlLabel',
                    '$mainImage', '$thumbnailImage', '$locale', '$country',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'name', 'game', 'gameCode', 'gameName', 'organization', 'organizationName', 'bunch', 'bunchCode', 'bunchName', 'occurrence',
            'longDescription', 'collectModeEmail', 'collectModePhone', 'collectModeMessage', 'collectModeUrl',
            'collectModeUrlType', 'collectModeUrlText', 'collectModeUrlLabel',
        ],
    },
    list: {
        columns: [
            { id: 'mainImage', label: 'column_main_image_label', render: 'image', flex: 0, width: 200 },
            { id: 'game', label: 'column_game_code_label', component: 'game', flex: 1 },
            { id: 'bunch', label: 'column_bunch_code_label', component: 'bunch', flex: 1 },
            { id: 'name', label: 'column_name_label' } as any,
            { id: 'occurrence', type: 'number', label: 'column_occurrence_label', flex: 0, width: 100 },
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
